jQuery(document).ready(function($) {
	/* Mobile menu */
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	}, {
		fixedElements: {
			elemInsertMethod: "appendTo"
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($(".mobile-navigation-menu").hasClass("mm-menu_opened")) {
			$(".mobile-navigation-menu").data("mmenu").close();
		}
		else {
			$(".mobile-navigation-menu").data("mmenu").open();
		}
	});

	/* FitVids */
	if ($("body").innerWidth() <= 767) {
		$(".inside-row-nested").fitVids({
			ignore: "nofit"
		});
		$(".home-text").fitVids({
			ignore: "nofit"
		});
	}

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-dropdown-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-dropdown-wrapper").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-dropdown-wrapper").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();


	/* Webflow */
	/* eslint-disable */
	Webflow.require('ix').init([{
			"slug": "dropdown-hover",
			"name": "Dropdown Hover",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"selector": ".nav-dropdown-links",
					"stepsA": [{
						"display": "flex",
						"opacity": 1,
						"height": "auto",
						"transition": "height 250ms ease 0, opacity 350ms ease 0, transform 350ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"height": "0px",
						"transition": "opacity 200ms ease 0, height 200 ease 0, transform 350ms ease 0"
					}, {
						"display": "none"
					}]
				}]
			}
		},
		{
			"slug": "product-hover-in",
			"name": "Product Hover In",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"selector": ".product-image",
					"descend": true,
					"preserve3d": true,
					"stepsA": [{
						"transition": "transform 300ms ease 0",
						"scaleX": 1.05,
						"scaleY": 1.05,
						"scaleZ": 1
					}],
					"stepsB": [{
						"transition": "transform 300ms ease 0",
						"scaleX": 1,
						"scaleY": 1,
						"scaleZ": 1
					}]
				}]
			}
		},
		{
			"slug": "search-open",
			"name": "Search Open",
			"value": {
				"style": {},
				"triggers": [{
					"type": "click",
					"selector": ".search-bar-block",
					"stepsA": [{
						"display": "flex",
						"width": "auto",
						"transition": "width 200 ease 0"
					}],
					"stepsB": []
				}]
			}
		},
		{
			"slug": "search-close",
			"name": "Search Close",
			"value": {
				"style": {},
				"triggers": [{
					"type": "click",
					"selector": ".search-bar-block",
					"stepsA": [{
						"display": "none"
					}],
					"stepsB": []
				}]
			}
		}
	]);
	/* eslint-enable */
});
